import { Constants } from '@yescapa-dev/ysc-api-js/legacy'

export const useInsuranceProviderText = (
  {
    insuranceCoverage,
    isReinforced,
    slug,
  }:
  {
    insuranceCoverage?: 'OWNER' | 'BASIC' | 'FULL' | null
    isReinforced?: boolean
    slug?: string | null
  },
) => {
  const { $i18n: { t } } = useNuxtApp()

  // v4 bookingGuestOwner's insurance guessing
  if (insuranceCoverage !== undefined) {
    if (insuranceCoverage === 'BASIC') {
      return t('data.insurance.name.insurance_basic')
    }

    if (insuranceCoverage === 'FULL') {
      return t('data.insurance.name.insurance_full')
    }

    if (insuranceCoverage === 'OWNER') {
      return t('data.insurance.name.insurance_pro')
    }

    if (insuranceCoverage === null) {
      return t('data.insurance.name.no_insurance')
    }
  }

  // v3 insurance guessing
  if (isReinforced) {
    return t('data.insurance.name.insurance_full')
  }

  if (slug === null) {
    return t('data.insurance.name.no_insurance')
  }

  if (slug === Constants.INSURANCES.OWNER_MANAGED_INSURANCE_SLUG) {
    return t('data.insurance.name.insurance_pro')
  }

  if (slug) {
    return t('data.insurance.name.insurance_basic')
  }

  // you should have had a problem before reaching this point
  return ''
}
